import PropTypes from 'prop-types';

const Divider = (props) => {
  const {
    vertical,
    size,
    otherSize,
    color,
    className,
    inherit,
    style,
    colored
  } = props;

  const heightInherit = inherit ? 'inherit' : `${size}px`;

  return (
    <div
      className={className}
      style={{
        width: vertical ? otherSize || '1px' : `${size}%`,
        height: vertical ? heightInherit : '1px',
        backgroundColor: colored ? 'var(--color-primary)' : color,
        ...style
      }}
    />
  );
};

Divider.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  otherSize: PropTypes.string,
  vertical: PropTypes.bool,
  inherit: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  colored: PropTypes.bool
};

Divider.defaultProps = {
  size: 100,
  color: 'var(--color-grey-light)',
  otherSize: '',
  vertical: false,
  inherit: false,
  className: '',
  style: {},
  colored: false
};

export default Divider;
